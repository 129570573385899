/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Module {
  height: calc(100vh - 60px);
}
.Module .module-header {
  background-color: #272e34;
  min-height: 62px;
  clear: both;
}
.Module .module-header .wrap {
  display: inline-flex;
  max-width: 100%;
  position: relative;
  top: 0px;
  left: 0px;
}
.Module .module-header .module-header-info {
  height: 62px;
}
.Module .module-header .module-header-info .module-header-banner {
  font-size: x-large;
  text-align: center;
}
.Module .module-header .module-header-info .module-header-title {
  text-align: center;
}
.Module .module-header .module-header-info .module-header-title i,
.Module .module-header .module-header-info .module-header-title a {
  color: #0df;
}
.Module .module-header .module-header-info .module-header-title a {
  font-size: 1rem;
}
.Module .module-header .module-header-info .module-header-title i {
  transform: scale(0.9);
}
.Module .module-header .header-selected {
  border-radius: 0;
}
.Module .module-header .view-exit {
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}
.Module .module-header .view-title {
  float: left;
  padding-top: 10px;
  padding-left: 10px;
}
.Module .module-header .view-title .material-icons {
  color: #fff;
  font-size: 24px;
  padding-left: 20px;
}
.Module .module-header .header-bar {
  float: left;
  margin-left: 10px;
}
.Module .module-header .header-bar a .btn {
  margin-right: 10px;
}
.Module .module-header .header-bar .form-inline {
  float: left;
  padding-top: 11px;
  font-size: 1rem;
}
.Module .module-header .header-bar .form-inline .input-group {
  margin-right: 20px;
}
.Module .module-header .header-bar .form-inline input {
  display: inline-block;
}
.Module .module-header .header-bar .form-inline label {
  padding-right: 5px;
  text-transform: capitalize;
}
.Module .module-header .header-bar .form-inline ::placeholder {
  color: #dae0e7;
}
.Module .module-header .secondary-header-toolbar {
  float: right;
  margin-right: 10px;
}
.Module .module-header .header-menu {
  background-color: #272e34;
}
.Module .module-header .header-selector {
  padding: 10px;
}
.Module .module-header .header-selector label {
  padding-top: 10px;
  padding-right: 10px;
  margin: 0;
}
.Module .module-header .header-selector .material-icons {
  padding-top: 5px;
}
.Module .module-header .header-selector .dropdown-menu-dark button {
  color: #aaaeb3;
  background-color: #272e34;
}
.Module .module-header .header-disabled {
  visibility: hidden;
}
.Module .module-header .btn-toolbar .header-button-group .material-icons {
  position: relative;
  top: 2px;
  color: #aaaeb3;
  padding-right: 5px;
  font-size: 16px;
}
.Module .module-header .btn-toolbar .header-button-group .selected .material-icons {
  color: #4bb3d8;
}
.Module .module-header .btn-toolbar .btn-group {
  margin-right: 10px;
}
.Module .module-header .btn-toolbar .btn-group .btn-secondary {
  outline: none;
}
.Module .module-header .btn-toolbar .btn-group .btn-secondary:focus {
  outline: none;
  box-shadow: none;
}
.Module .module-header .btn-toolbar .btn-group .btn {
  margin-right: 0;
}
.Module .module-header .btn-toolbar .date-range-group {
  margin-right: 0;
}
.Module .module-header .btn-toolbar .date-range-group .selected {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-header .btn-toolbar .date-range-group .selected .material-icons {
  color: #fff;
}
.Module .module-content {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #272e34;
  height: calc(100% - 62px);
}
.Module .module-content .header-id {
  font-size: small;
  color: #aaaeb3;
  padding-left: 10px;
}
.Module .module-content .content-view {
  height: 100%;
}
.Module .module-content .force {
  position: fixed;
  bottom: 0;
  margin-right: 20px;
  width: calc(100vw - 190px - 10px);
}
.Module .module-content .content-view-controls {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0;
  padding-left: 0;
  height: 50px;
  background-color: #272e34;
}
.Module .module-content .content-view-controls a .btn:last-child {
  margin-right: 10px;
}
.Module .module-content .content-view-controls .header-button-group .btn {
  margin: 0;
}
.Module .module-content .content-view-controls label {
  margin-top: 5px;
  margin-right: 5px;
}
.Module .module-content .content-view-controls form {
  margin-right: 10px;
}
.Module .module-content .content-view-controls form .form-check-label-color {
  border-radius: 10px;
  padding: 0.25rem 0.5rem;
  margin-top: 0;
}
.Module .module-content .content-view-controls .form-check-inline {
  margin-right: 0;
}
.Module .module-content .search-bar {
  position: fixed;
  width: calc(100% - 190px);
  padding-right: 20px;
}
.Module .module-content .search-bar .input-group {
  padding-right: 5px;
}
.Module .module-content .search-bar .input-group .input-group-text {
  background-color: #272e34;
  color: #fff;
}
.Module .module-content .search-bar .input-group button,
.Module .module-content .search-bar .input-group input {
  background-color: #1f1f1f;
  color: #fff;
  border-color: #fff;
}
.Module .module-content .search-bar .input-group-sm {
  padding-right: 5px;
}
.Module .module-content .search-bar .input-group-sm .input-group-text {
  background-color: #272e34;
  color: #fff;
  border-radius: 10px;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  font-size: 14px;
}
.Module .module-content .search-bar .input-group-sm button,
.Module .module-content .search-bar .input-group-sm input {
  background-color: #1f1f1f;
  color: #fff;
  border-color: #fff;
  border-radius: 10px;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  font-size: 14px;
}
.Module .module-content .search-bar .form-check-label {
  padding: 0 5px;
  margin-right: 10px;
  border-radius: 5px;
}
.Module .module-content .content {
  float: left;
  width: calc(100% - 330px - 10px - 2px);
  height: 100%;
  background-color: #353c44;
}
.Module .module-content .content .content-with-title {
  height: 100%;
  border-bottom: solid 1px #272e34;
  overflow: auto;
}
.Module .module-content .content .content-main {
  height: calc(100% - 300px);
  border-bottom: solid 1px #272e34;
  overflow: auto;
}
.Module .module-content .content .content-main-full {
  height: 100%;
}
.Module .module-content .content .content-footer {
  height: 300px;
  width: 100%;
}
.Module .module-content .content .ok {
  background-color: #4caf50;
}
.Module .module-content .content .warning {
  background-color: orange;
}
.Module .module-content .content .error {
  background-color: #e22006;
}
.Module .module-content .content .no-status {
  background-color: #272e34;
}
.Module .module-content .content .no-match {
  text-align: center;
}
.Module .module-content .content .content-title {
  text-align: center;
  padding: 10px;
  margin-bottom: 0;
}
.Module .module-content .content .content-subtitle {
  text-align: center;
  padding: 10px;
  margin: 0;
}
.Module .module-content .content .site-content {
  padding-bottom: 50px;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.ok {
  color: #4caf50;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.warning {
  color: orange;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.error {
  color: #e22006;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.no-status {
  color: #fff;
}
.Module .module-content .content .site-content td.ranged {
  background-color: #272e34;
}
.Module .module-content .content .system-content {
  clear: both;
}
.Module .module-content .content table {
  width: 100%;
  border-collapse: separate;
  /* Don't collapse as this makes sticky headers see through */
  border-spacing: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.Module .module-content .content table th {
  top: 0;
  border-bottom: 2px solid #272e34;
  background-color: #353c44;
  z-index: 1;
  color: #4bb3d8;
  text-align: left;
}
.Module .module-content .content table th.ranged {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-content .content table td {
  border: solid 1px #272e34;
}
.Module .module-content .content table td a {
  color: #fff;
}
.Module .module-content .content table td .btn-link {
  padding: 0;
  padding-left: 5px;
}
.Module .module-content .content table td .btn-link .material-icons {
  color: #4bb3d8;
}
.Module .module-content .content table td.danger {
  background-color: #e22006;
}
.Module .module-content .content table td.ranged {
  background-color: #353c44;
}
.Module .module-content .content table th.edit {
  width: 20px;
}
.Module .module-content .content .content-map {
  height: 100%;
  width: 100%;
}
.Module .module-content .content nav {
  background-color: #272e34;
  z-index: 100;
  color: #fff;
}
.Module .module-content .content nav .react-contextmenu-item {
  color: #fff;
}
.Module .module-content .content nav .ok > .react-contextmenu-item {
  background-color: #4caf50;
}
.Module .module-content .content nav .warning > .react-contextmenu-item {
  background-color: orange;
}
.Module .module-content .content nav .error > .react-contextmenu-item {
  background-color: #e22006;
}
.Module .module-content .content nav .no-nodes > .react-contextmenu-item {
  padding: 0 10px;
}
.Module .module-content .content .content-graph-menu {
  text-align: center;
  background-color: #272e34;
  clear: both;
}
.Module .module-content .content .content-graph-menu .content-graph-menu-left {
  font-weight: bold;
  float: left;
  margin-left: 10px;
}
.Module .module-content .content .content-graph-menu .content-graph-menu-right {
  float: right;
  margin-right: 10px;
}
.Module .module-content .content .content-graph-menu .dropdown {
  display: inline;
  z-index: 10;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-menu {
  background-color: #1f1f1f;
}
.Module .module-content .content .content-graph-menu .dropdown button {
  color: #fff;
  background-color: #272e34;
  border: none;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-item:hover {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-toggle {
  color: #fff;
  box-shadow: none;
}
.Module .module-content .content .content-graph-menu .dropdown .selected {
  color: #4bb3d8;
}
.Module .module-content .content .content-graph-empty {
  text-align: center;
  font-size: large;
  padding-top: 100px;
}
.Module .module-content .content .content-graph,
.Module .module-content .content .content-source-graph {
  width: 100%;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper {
  pointer-events: auto;
  margin-left: -5px;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper .recharts-default-tooltip,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper .recharts-default-tooltip {
  overflow-y: auto;
  overflow-x: hidden;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list {
  max-height: 165px;
}
.Module .module-content .content .content-graph {
  height: calc(300px - 30px);
}
.Module .module-content .content .content-source-graph {
  height: calc(300px + 230px);
}
.Module .module-content .content-full {
  width: 100%;
  overflow-y: auto;
}
.Module .module-content .content-full-with-title {
  width: 100%;
  height: calc(100% - 66px);
  overflow-y: auto;
}
.Module .module-content .close-button {
  top: 0;
  font-size: 48px;
}
.Module .module-content .item-details {
  height: 100%;
}
.Module .module-content .item-details .item-details-title {
  padding: 10px 20px;
  font-size: large;
}
.Module .module-content .item-details .item-details-title h2 {
  padding: 0;
}
.Module .module-content .item-details .item-details-title h2 .right-title {
  float: right;
  font-size: medium;
  padding-top: 14px;
}
.Module .module-content .item-details .item-create-title {
  background-color: #4bb3d8;
}
.Module .module-content .item-details .item-details-content {
  overflow: auto;
  height: calc(100% - 66px);
  background-color: #272e34;
  padding: 20px;
}
.Module .module-content .item-details .item-details-content label {
  color: #4bb3d8;
  margin-right: 5px;
}
.Module .module-content .item-details .item-details-content .ecogy-form label {
  margin-right: 0;
}
.Module .module-content .item-details .item-details-content .type {
  text-transform: capitalize;
}
.Module .module-content .item-details .item-details-content hr {
  margin: 2px;
  border-top-color: #dae0e7;
}
.Module .module-content .item-details .item-details-content .remove-button {
  margin-bottom: 20px;
}
.Module .module-content .item-details .item-details-content form > .form-group fieldset > .form-group {
  width: 50%;
  padding-right: 10px;
}
.Module .module-content .item-details .item-details-content form > .form-group > div > div > fieldset > .form-group {
  width: 50%;
  padding-right: 10px;
}
.Module .module-content .item-details .item-details-content .right-column {
  float: right;
  clear: both;
  padding-right: 0;
  padding-left: 10px;
}
.Module .module-content .content-sidebar {
  margin-left: 10px;
  float: left;
  width: 330px;
  height: 100%;
  overflow: auto;
}
.Module .module-content .content-sidebar .sidebar-section {
  margin-top: 10px;
  background-color: #353c44;
  width: 100%;
  padding: 10px;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-title-wrapper {
  display: flex;
  justify-content: space-between;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-title {
  width: 100%;
  color: #fff;
  text-transform: uppercase;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-subtitle {
  color: #aaaeb3;
  float: right;
  margin: 0;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-range {
  color: #aaaeb3;
  margin: 0;
  font-size: small;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content {
  width: 100%;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-heading {
  font-size: xx-large;
  font-weight: bold;
  color: #4bb3d8;
  text-align: center;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .positive {
  color: #00c4a0;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .negative {
  color: #ff9b91;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-text {
  text-align: center;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-text .material-icons {
  float: right;
  padding-top: 10px;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .siderbar-reading {
  color: #4bb3d8;
  font-size: x-large;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .warning {
  color: orange;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .siderbar-error {
  color: #4bb3d8;
  font-size: large;
  text-align: center;
  padding: 10px 0;
}
.Module .module-content .content-sidebar :first-child {
  margin: 0;
}
.Module .module-content .content-sidebar .date-range {
  color: #aaaeb3;
}
.Module .module-content .content-sidebar .date-range .start-date {
  color: #fff;
}
.Module .module-content .content-sidebar .date-range .end-date {
  color: #fff;
}
.Module .module-content .content-sidebar .date-range .date-label {
  width: 50px;
}
.Module .module-content .recharts-tooltip-cursor {
  fill-opacity: 25%;
}
.Module .module-content .content-with-controls {
  height: calc(100% - 50px);
}
.Module .collapsed {
  height: calc(100% - 62px - 41px);
}

.App-portfolio .module-header {
  background-color: #4bb3d8;
  min-height: 61px;
  border-bottom: 1px solid black;
}
.App-portfolio .module-content {
  margin-top: 10px;
  height: calc(100% - 62px - 10px);
}
.App-portfolio .collapsed {
  height: calc(100% - 62px - 41px - 10px);
}

.area-graph-tooltip {
  background: #fff;
  padding: 10px;
  color: #272e34;
  text-transform: capitalize;
}
.area-graph-tooltip p {
  margin: 0;
}

.econode-tooltip .area-graph-tooltip,
.ecogy-tooltip .area-graph-tooltip {
  text-transform: none;
}

.modal {
  color: #000;
}
.modal .error {
  color: #e22006;
}

.custom-range-button {
  padding-top: 0;
  padding-bottom: 0;
}

.custom-range-name > * {
  float: left;
}
.custom-range-name .range .range-start {
  font-size: small;
}
.custom-range-name .range .range-end {
  font-size: small;
}
.custom-range-name .range .range-divider {
  display: none;
}

.date-range-menu {
  width: 700px;
  height: 370px;
  left: auto;
  right: 0;
}
.date-range-menu .custom-date-range {
  float: left;
  width: 510px;
}
.date-range-menu .custom-date-range .custom-date {
  float: left;
  width: 250px;
  margin-left: 5px;
  height: 310px;
}
.date-range-menu .precanned-date-ranges {
  float: left;
  margin-left: 10px;
  height: 100%;
  width: 170px;
  background-color: #353c44;
  border-radius: 5px;
  padding: 10px;
}
.date-range-menu .precanned-date-ranges .dropdown-item {
  padding: 0.25rem;
  border: none;
}
.date-range-menu .precanned-date-ranges .selected {
  color: #fff;
  background-color: #4bb3d8;
}
.date-range-menu .date-range-buttons {
  clear: both;
}
.date-range-menu .date-range-buttons .date-range-button {
  border-radius: 10px;
  float: right;
}
.date-range-menu .date-range-message {
  width: 100%;
  color: #4bb3d8;
  clear: both;
  padding: 8px 8px 0;
}
.date-range-menu .date-range-message .material-icons {
  color: #4bb3d8;
}
.date-range-menu .date-range-warning {
  width: 100%;
  color: #e22006;
  clear: both;
  padding: 8px 8px 0;
}
.date-range-menu .date-range-warning .material-icons {
  color: #e22006;
}

.react-select__option {
  color: rgb(29, 33, 39) !important;
}

.battery-view-header {
  text-align: center;
}

.battery-box {
  height: 150px;
  width: 200px;
  background-color: rgb(29, 33, 39);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 75px 75px;
  color: grey;
}

.battery-container {
  text-align: center;
  align-items: center;
  justify-items: center;
  height: 350px;
  width: 250px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 80px 230px;
  grid-template-rows: 80px 150px 80px;
  color: rgb(207, 205, 205);
}
.battery-container p {
  font-size: medium;
}
.battery-container .state-of-health {
  margin-top: 8px;
  margin-bottom: 40px;
  background-color: #193138;
  border-radius: 5px;
  font-size: small;
}

.graph-tail-end {
  height: 8px;
  width: 8px;
  background-color: #00C49F;
  bottom: 180px;
  left: 0px;
  position: absolute;
  border-radius: 5px;
  z-index: 100;
}

.battery-cell {
  margin-right: 10px;
  margin-left: 10px;
  background-color: #1c3841;
  height: 100px;
  width: 70px;
  position: relative;
}
.battery-cell .logo {
  position: relative;
  top: 40px;
  left: -14px;
  max-width: 100px;
  filter: opacity(0.6) drop-shadow(0 0 0 rgb(0, 0, 0));
  transform: rotate(270deg);
}
.battery-cell .charge-bar {
  margin: 5px;
  position: absolute;
  width: 60px;
  bottom: 0px;
  background-color: #4caf50;
}
.battery-cell .empty {
  visibility: hidden;
}
.battery-cell .low {
  background-color: #e22006;
}
.battery-cell .high {
  background-color: #4caf50;
}
.battery-cell .charge-bar-wrapper {
  position: absolute;
  height: 90px;
  width: 60px;
  bottom: 0px;
}
.battery-cell .charge-bar-top {
  clip-path: ellipse(30px 10px);
  height: 20px;
  position: absolute;
  width: 60px;
  top: -10px;
  box-shadow: inset 0px -6px 12px 0px hsla(0, 0%, 0%, 0.2);
}
.battery-cell .charge-bar-bottom {
  clip-path: ellipse(30px 10px);
  height: 20px;
  position: absolute;
  width: 60px;
  bottom: -10px;
}
.battery-cell .battery-cell-top {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  top: -10px;
  background-color: #193138;
  z-index: 100;
}
.battery-cell .battery-cell-top2 {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  top: -5px;
  background-color: #1c3841;
  z-index: 99;
}
.battery-cell .battery-cell-bottom {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  bottom: -10px;
  background-color: #1c3841;
}

.side-by-side {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

[class|=confetti] {
  position: absolute;
  z-index: 3000;
}

.papers-origin {
  position: absolute;
  overflow: visible;
  top: 50%;
  left: 50%;
}

.confetti-0 {
  width: 1px;
  height: 0.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 12px;
  opacity: 0;
  transform: rotate(117.8635989106deg);
  animation: drop-0 3.7738441785s 0.0169321977s 3;
}

@keyframes drop-0 {
  25% {
    top: -18vh;
    left: 9vw;
    opacity: 0.8425711675;
  }
  100% {
    top: 50vh;
    left: 22vw;
    opacity: 0;
  }
}
.confetti-1 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(69.8716358507deg);
  animation: drop-1 3.620982696s 0.1062724238s 3;
}

@keyframes drop-1 {
  25% {
    top: -21vh;
    left: 1vw;
    opacity: 1.2473148021;
  }
  100% {
    top: 50vh;
    left: 11vw;
    opacity: 0;
  }
}
.confetti-2 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(207.8273783777deg);
  animation: drop-2 3.1719791966s 0.7489115002s 3;
}

@keyframes drop-2 {
  25% {
    top: -15vh;
    left: 11vw;
    opacity: 0.5503934988;
  }
  100% {
    top: 50vh;
    left: 11vw;
    opacity: 0;
  }
}
.confetti-3 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: -4px;
  opacity: 0;
  transform: rotate(284.190370426deg);
  animation: drop-3 3.3375426278s 0.0741865106s 3;
}

@keyframes drop-3 {
  25% {
    top: -17vh;
    left: -5vw;
    opacity: 1.3189094264;
  }
  100% {
    top: 50vh;
    left: 10vw;
    opacity: 0;
  }
}
.confetti-4 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: -8px;
  opacity: 0;
  transform: rotate(32.7635155586deg);
  animation: drop-4 3.5696866201s 0.1320879046s 3;
}

@keyframes drop-4 {
  25% {
    top: -4vh;
    left: 3vw;
    opacity: 0.9400301898;
  }
  100% {
    top: 50vh;
    left: 13vw;
    opacity: 0;
  }
}
.confetti-5 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(346.3566496275deg);
  animation: drop-5 3.9781474231s 0.5418379934s 3;
}

@keyframes drop-5 {
  25% {
    top: -6vh;
    left: 10vw;
    opacity: 0.5482513758;
  }
  100% {
    top: 50vh;
    left: 11vw;
    opacity: 0;
  }
}
.confetti-6 {
  width: 1px;
  height: 0.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 3px;
  opacity: 0;
  transform: rotate(298.959926216deg);
  animation: drop-6 3.4717896254s 0.3502073241s 3;
}

@keyframes drop-6 {
  25% {
    top: -8vh;
    left: 13vw;
    opacity: 0.9929247818;
  }
  100% {
    top: 50vh;
    left: 28vw;
    opacity: 0;
  }
}
.confetti-7 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(283.7055336168deg);
  animation: drop-7 3.9962330766s 0.3023657183s 3;
}

@keyframes drop-7 {
  25% {
    top: -23vh;
    left: -8vw;
    opacity: 1.3177915814;
  }
  100% {
    top: 50vh;
    left: -6vw;
    opacity: 0;
  }
}
.confetti-8 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: 2px;
  opacity: 0;
  transform: rotate(25.3098122248deg);
  animation: drop-8 3.9798732284s 0.5692012881s 3;
}

@keyframes drop-8 {
  25% {
    top: -8vh;
    left: 15vw;
    opacity: 0.8698759032;
  }
  100% {
    top: 50vh;
    left: 17vw;
    opacity: 0;
  }
}
.confetti-9 {
  width: 4px;
  height: 1.6px;
  background-color: #42f212;
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(36.6008684647deg);
  animation: drop-9 3.4379314635s 0.4116904752s 3;
}

@keyframes drop-9 {
  25% {
    top: -25vh;
    left: 1vw;
    opacity: 0.9947568213;
  }
  100% {
    top: 50vh;
    left: 2vw;
    opacity: 0;
  }
}
.confetti-10 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(84.4898277328deg);
  animation: drop-10 3.4536063837s 0.1871777065s 3;
}

@keyframes drop-10 {
  25% {
    top: -25vh;
    left: -5vw;
    opacity: 0.615472281;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-11 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(162.9367459446deg);
  animation: drop-11 3.5925542401s 0.3963667755s 3;
}

@keyframes drop-11 {
  25% {
    top: -2vh;
    left: 0vw;
    opacity: 1.4641922149;
  }
  100% {
    top: 50vh;
    left: 15vw;
    opacity: 0;
  }
}
.confetti-12 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(124.8784473587deg);
  animation: drop-12 3.8785740649s 0.9748168297s 3;
}

@keyframes drop-12 {
  25% {
    top: -1vh;
    left: 6vw;
    opacity: 1.4264567269;
  }
  100% {
    top: 50vh;
    left: 16vw;
    opacity: 0;
  }
}
.confetti-13 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(251.0648842108deg);
  animation: drop-13 3.2262511011s 0.4947179508s 3;
}

@keyframes drop-13 {
  25% {
    top: -22vh;
    left: -6vw;
    opacity: 1.0688712601;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-14 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(205.4716244985deg);
  animation: drop-14 3.4312567869s 0.2203046997s 3;
}

@keyframes drop-14 {
  25% {
    top: -11vh;
    left: -6vw;
    opacity: 0.7898059376;
  }
  100% {
    top: 50vh;
    left: -9vw;
    opacity: 0;
  }
}
.confetti-15 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(338.790194244deg);
  animation: drop-15 3.3840241918s 0.2345589021s 3;
}

@keyframes drop-15 {
  25% {
    top: -8vh;
    left: 14vw;
    opacity: 1.2231723075;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-16 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(117.1590585632deg);
  animation: drop-16 3.8479680744s 0.9148068877s 3;
}

@keyframes drop-16 {
  25% {
    top: -6vh;
    left: 0vw;
    opacity: 0.9149432346;
  }
  100% {
    top: 50vh;
    left: 10vw;
    opacity: 0;
  }
}
.confetti-17 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(264.7555409208deg);
  animation: drop-17 3.816820754s 0.8079133795s 3;
}

@keyframes drop-17 {
  25% {
    top: -19vh;
    left: 11vw;
    opacity: 1.1622845881;
  }
  100% {
    top: 50vh;
    left: 12vw;
    opacity: 0;
  }
}
.confetti-18 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(276.6465332424deg);
  animation: drop-18 3.0295504031s 0.376631932s 3;
}

@keyframes drop-18 {
  25% {
    top: -20vh;
    left: 10vw;
    opacity: 0.5411313864;
  }
  100% {
    top: 50vh;
    left: 18vw;
    opacity: 0;
  }
}
.confetti-19 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: -6px;
  opacity: 0;
  transform: rotate(9.6736286523deg);
  animation: drop-19 3.9254112991s 0.2906170638s 3;
}

@keyframes drop-19 {
  25% {
    top: -4vh;
    left: -8vw;
    opacity: 1.1521676431;
  }
  100% {
    top: 50vh;
    left: -21vw;
    opacity: 0;
  }
}
.confetti-20 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(348.3492749429deg);
  animation: drop-20 3.4755556361s 0.5488085758s 3;
}

@keyframes drop-20 {
  25% {
    top: -13vh;
    left: 7vw;
    opacity: 0.7551493012;
  }
  100% {
    top: 50vh;
    left: 21vw;
    opacity: 0;
  }
}
.confetti-21 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -10px;
  opacity: 0;
  transform: rotate(300.1197213077deg);
  animation: drop-21 3.8502216537s 0.8762450128s 3;
}

@keyframes drop-21 {
  25% {
    top: -25vh;
    left: -3vw;
    opacity: 1.4701092708;
  }
  100% {
    top: 50vh;
    left: -13vw;
    opacity: 0;
  }
}
.confetti-22 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(18.2338735319deg);
  animation: drop-22 3.5375061935s 0.4843392365s 3;
}

@keyframes drop-22 {
  25% {
    top: -9vh;
    left: -3vw;
    opacity: 0.7521879031;
  }
  100% {
    top: 50vh;
    left: -13vw;
    opacity: 0;
  }
}
.confetti-23 {
  width: 1px;
  height: 0.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(248.1130309046deg);
  animation: drop-23 3.6695848308s 0.5566993456s 3;
}

@keyframes drop-23 {
  25% {
    top: -12vh;
    left: -4vw;
    opacity: 0.6191433896;
  }
  100% {
    top: 50vh;
    left: -8vw;
    opacity: 0;
  }
}
.confetti-24 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(155.0617994455deg);
  animation: drop-24 3.5740678901s 0.4436492385s 3;
}

@keyframes drop-24 {
  25% {
    top: -5vh;
    left: 9vw;
    opacity: 1.2519896205;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-25 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: -14px;
  opacity: 0;
  transform: rotate(326.5628672088deg);
  animation: drop-25 3.8497081071s 0.8527080919s 3;
}

@keyframes drop-25 {
  25% {
    top: -17vh;
    left: 7vw;
    opacity: 1.4392229185;
  }
  100% {
    top: 50vh;
    left: 13vw;
    opacity: 0;
  }
}
.confetti-26 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(298.0706374462deg);
  animation: drop-26 3.9342434636s 0.7344532613s 3;
}

@keyframes drop-26 {
  25% {
    top: -11vh;
    left: 12vw;
    opacity: 0.8661556054;
  }
  100% {
    top: 50vh;
    left: 25vw;
    opacity: 0;
  }
}
.confetti-27 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(110.2263108622deg);
  animation: drop-27 3.603358258s 0.4626631301s 3;
}

@keyframes drop-27 {
  25% {
    top: -25vh;
    left: -1vw;
    opacity: 1.1819080338;
  }
  100% {
    top: 50vh;
    left: -7vw;
    opacity: 0;
  }
}
.confetti-28 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(209.0448092449deg);
  animation: drop-28 3.4907489769s 0.6531589395s 3;
}

@keyframes drop-28 {
  25% {
    top: -6vh;
    left: -10vw;
    opacity: 1.2929671808;
  }
  100% {
    top: 50vh;
    left: -23vw;
    opacity: 0;
  }
}
.confetti-29 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(143.694740293deg);
  animation: drop-29 3.1824854544s 0.6746709217s 3;
}

@keyframes drop-29 {
  25% {
    top: -8vh;
    left: 4vw;
    opacity: 1.2425333746;
  }
  100% {
    top: 50vh;
    left: 8vw;
    opacity: 0;
  }
}
.confetti-30 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(137.7238368019deg);
  animation: drop-30 3.961628059s 0.0422690646s 3;
}

@keyframes drop-30 {
  25% {
    top: -13vh;
    left: 3vw;
    opacity: 1.3714008561;
  }
  100% {
    top: 50vh;
    left: 14vw;
    opacity: 0;
  }
}
.confetti-31 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: -14px;
  opacity: 0;
  transform: rotate(201.1921219913deg);
  animation: drop-31 3.3124886458s 0.4832166114s 3;
}

@keyframes drop-31 {
  25% {
    top: -19vh;
    left: -11vw;
    opacity: 1.3245233662;
  }
  100% {
    top: 50vh;
    left: -6vw;
    opacity: 0;
  }
}
.confetti-32 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(337.3969116061deg);
  animation: drop-32 3.6498070604s 0.5802990036s 3;
}

@keyframes drop-32 {
  25% {
    top: -20vh;
    left: 6vw;
    opacity: 1.0764177915;
  }
  100% {
    top: 50vh;
    left: 20vw;
    opacity: 0;
  }
}
.confetti-33 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(231.968480625deg);
  animation: drop-33 3.0458300515s 0.0250966144s 3;
}

@keyframes drop-33 {
  25% {
    top: -11vh;
    left: 10vw;
    opacity: 1.1275934101;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-34 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(226.487038538deg);
  animation: drop-34 3.5504791285s 0.4286852183s 3;
}

@keyframes drop-34 {
  25% {
    top: -19vh;
    left: 8vw;
    opacity: 1.3220090483;
  }
  100% {
    top: 50vh;
    left: -2vw;
    opacity: 0;
  }
}
.confetti-35 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(261.612321616deg);
  animation: drop-35 3.7451777166s 0.2762102198s 3;
}

@keyframes drop-35 {
  25% {
    top: -1vh;
    left: 6vw;
    opacity: 1.3050303906;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-36 {
  width: 1px;
  height: 0.4px;
  background-color: #42f212;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(313.3911614173deg);
  animation: drop-36 3.7913125451s 0.066816094s 3;
}

@keyframes drop-36 {
  25% {
    top: -24vh;
    left: -11vw;
    opacity: 0.6839930735;
  }
  100% {
    top: 50vh;
    left: -8vw;
    opacity: 0;
  }
}
.confetti-37 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(41.3767080463deg);
  animation: drop-37 3.9037122228s 0.2833292125s 3;
}

@keyframes drop-37 {
  25% {
    top: -24vh;
    left: -2vw;
    opacity: 0.6890071236;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-38 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(258.9030763812deg);
  animation: drop-38 3.9399883442s 0.4658536017s 3;
}

@keyframes drop-38 {
  25% {
    top: -23vh;
    left: 14vw;
    opacity: 0.6113081029;
  }
  100% {
    top: 50vh;
    left: 24vw;
    opacity: 0;
  }
}
.confetti-39 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: 3px;
  opacity: 0;
  transform: rotate(142.5425387267deg);
  animation: drop-39 3.6550536545s 0.2998564905s 3;
}

@keyframes drop-39 {
  25% {
    top: -9vh;
    left: 8vw;
    opacity: 0.6676361253;
  }
  100% {
    top: 50vh;
    left: 2vw;
    opacity: 0;
  }
}
.confetti-40 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(344.044726882deg);
  animation: drop-40 3.2987382074s 0.859159635s 3;
}

@keyframes drop-40 {
  25% {
    top: -14vh;
    left: -5vw;
    opacity: 1.4826164423;
  }
  100% {
    top: 50vh;
    left: -17vw;
    opacity: 0;
  }
}
.confetti-41 {
  width: 4px;
  height: 1.6px;
  background-color: #42f212;
  top: 0px;
  left: -4px;
  opacity: 0;
  transform: rotate(81.9312036374deg);
  animation: drop-41 3.3667683881s 0.7183631436s 3;
}

@keyframes drop-41 {
  25% {
    top: -11vh;
    left: 13vw;
    opacity: 1.2587299717;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-42 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(309.3300103504deg);
  animation: drop-42 3.4747225927s 0.6150116022s 3;
}

@keyframes drop-42 {
  25% {
    top: -4vh;
    left: 3vw;
    opacity: 1.0024172337;
  }
  100% {
    top: 50vh;
    left: 9vw;
    opacity: 0;
  }
}
.confetti-43 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: 8px;
  opacity: 0;
  transform: rotate(98.348109989deg);
  animation: drop-43 3.7813455106s 0.3588840767s 3;
}

@keyframes drop-43 {
  25% {
    top: -7vh;
    left: 0vw;
    opacity: 0.9915030337;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-44 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(282.3181080271deg);
  animation: drop-44 3.8132730737s 0.4688424324s 3;
}

@keyframes drop-44 {
  25% {
    top: -19vh;
    left: 0vw;
    opacity: 0.8521643406;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-45 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(96.3869447623deg);
  animation: drop-45 3.109307876s 0.2663751967s 3;
}

@keyframes drop-45 {
  25% {
    top: -23vh;
    left: 5vw;
    opacity: 1.2260072157;
  }
  100% {
    top: 50vh;
    left: -4vw;
    opacity: 0;
  }
}
.confetti-46 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 10px;
  opacity: 0;
  transform: rotate(43.7767860001deg);
  animation: drop-46 3.5648107975s 0.7202540896s 3;
}

@keyframes drop-46 {
  25% {
    top: -11vh;
    left: 5vw;
    opacity: 0.5909285384;
  }
  100% {
    top: 50vh;
    left: 11vw;
    opacity: 0;
  }
}
.confetti-47 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 5px;
  opacity: 0;
  transform: rotate(250.0666495376deg);
  animation: drop-47 3.7596319738s 0.2230015149s 3;
}

@keyframes drop-47 {
  25% {
    top: -8vh;
    left: -9vw;
    opacity: 0.5608153894;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-48 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(5.654096779deg);
  animation: drop-48 3.8826530766s 0.4217639631s 3;
}

@keyframes drop-48 {
  25% {
    top: -20vh;
    left: -9vw;
    opacity: 0.5099465824;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-49 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(210.4596424925deg);
  animation: drop-49 3.4163903388s 0.3482329427s 3;
}

@keyframes drop-49 {
  25% {
    top: -6vh;
    left: -1vw;
    opacity: 1.0065830714;
  }
  100% {
    top: 50vh;
    left: 7vw;
    opacity: 0;
  }
}
.confetti-50 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(26.6211029374deg);
  animation: drop-50 3.2583840045s 0.9058172364s 3;
}

@keyframes drop-50 {
  25% {
    top: -12vh;
    left: 9vw;
    opacity: 1.4394954807;
  }
  100% {
    top: 50vh;
    left: 18vw;
    opacity: 0;
  }
}
.confetti-51 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(56.37050713deg);
  animation: drop-51 3.7296859739s 0.3097102482s 3;
}

@keyframes drop-51 {
  25% {
    top: -22vh;
    left: 6vw;
    opacity: 0.8119648937;
  }
  100% {
    top: 50vh;
    left: -7vw;
    opacity: 0;
  }
}
.confetti-52 {
  width: 5px;
  height: 2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(235.1411471305deg);
  animation: drop-52 3.9042800305s 0.2276697727s 3;
}

@keyframes drop-52 {
  25% {
    top: -22vh;
    left: -2vw;
    opacity: 0.8161591145;
  }
  100% {
    top: 50vh;
    left: 7vw;
    opacity: 0;
  }
}
.confetti-53 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: 10px;
  opacity: 0;
  transform: rotate(329.9188998947deg);
  animation: drop-53 3.6409078246s 0.4208020145s 3;
}

@keyframes drop-53 {
  25% {
    top: -12vh;
    left: -2vw;
    opacity: 1.3273404573;
  }
  100% {
    top: 50vh;
    left: 8vw;
    opacity: 0;
  }
}
.confetti-54 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(140.5074616105deg);
  animation: drop-54 3.3928354457s 0.4603730974s 3;
}

@keyframes drop-54 {
  25% {
    top: -16vh;
    left: -9vw;
    opacity: 0.6932218826;
  }
  100% {
    top: 50vh;
    left: -8vw;
    opacity: 0;
  }
}
.confetti-55 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(329.1498987315deg);
  animation: drop-55 3.4434114857s 0.585153944s 3;
}

@keyframes drop-55 {
  25% {
    top: -2vh;
    left: -1vw;
    opacity: 0.8643325721;
  }
  100% {
    top: 50vh;
    left: -7vw;
    opacity: 0;
  }
}
.confetti-56 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: -14px;
  opacity: 0;
  transform: rotate(24.3008147692deg);
  animation: drop-56 3.0221617319s 0.7302729702s 3;
}

@keyframes drop-56 {
  25% {
    top: -18vh;
    left: 11vw;
    opacity: 0.702965419;
  }
  100% {
    top: 50vh;
    left: -2vw;
    opacity: 0;
  }
}
.confetti-57 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: 12px;
  opacity: 0;
  transform: rotate(334.7901161793deg);
  animation: drop-57 3.2260128512s 0.8945464132s 3;
}

@keyframes drop-57 {
  25% {
    top: -5vh;
    left: 15vw;
    opacity: 0.5929913347;
  }
  100% {
    top: 50vh;
    left: 18vw;
    opacity: 0;
  }
}
.confetti-58 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(339.9834829631deg);
  animation: drop-58 3.3166650627s 0.5889296783s 3;
}

@keyframes drop-58 {
  25% {
    top: -20vh;
    left: 10vw;
    opacity: 0.6207073933;
  }
  100% {
    top: 50vh;
    left: -3vw;
    opacity: 0;
  }
}
.confetti-59 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -12px;
  opacity: 0;
  transform: rotate(335.6826811873deg);
  animation: drop-59 3.0246993275s 0.8888968282s 3;
}

@keyframes drop-59 {
  25% {
    top: -10vh;
    left: 12vw;
    opacity: 1.4950206865;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-60 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: 8px;
  opacity: 0;
  transform: rotate(259.8849837129deg);
  animation: drop-60 3.9530672981s 0.8455700368s 3;
}

@keyframes drop-60 {
  25% {
    top: -16vh;
    left: 6vw;
    opacity: 0.5176751712;
  }
  100% {
    top: 50vh;
    left: 12vw;
    opacity: 0;
  }
}
.confetti-61 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -12px;
  opacity: 0;
  transform: rotate(353.4979124058deg);
  animation: drop-61 3.7693308792s 0.4058815653s 3;
}

@keyframes drop-61 {
  25% {
    top: -10vh;
    left: -4vw;
    opacity: 0.7250999225;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-62 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(272.6094553508deg);
  animation: drop-62 3.8382986573s 0.779496171s 3;
}

@keyframes drop-62 {
  25% {
    top: -15vh;
    left: 10vw;
    opacity: 1.281999775;
  }
  100% {
    top: 50vh;
    left: 14vw;
    opacity: 0;
  }
}
.confetti-63 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(356.6758748918deg);
  animation: drop-63 3.3478936991s 0.976925298s 3;
}

@keyframes drop-63 {
  25% {
    top: -2vh;
    left: 10vw;
    opacity: 1.2054108915;
  }
  100% {
    top: 50vh;
    left: -3vw;
    opacity: 0;
  }
}
.confetti-64 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: 4px;
  opacity: 0;
  transform: rotate(15.2677955214deg);
  animation: drop-64 3.4960062404s 0.5380003441s 3;
}

@keyframes drop-64 {
  25% {
    top: -22vh;
    left: -14vw;
    opacity: 0.9280564444;
  }
  100% {
    top: 50vh;
    left: -8vw;
    opacity: 0;
  }
}
.confetti-65 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(251.1001129388deg);
  animation: drop-65 3.8974846484s 0.8093665356s 3;
}

@keyframes drop-65 {
  25% {
    top: -4vh;
    left: -3vw;
    opacity: 1.1062321507;
  }
  100% {
    top: 50vh;
    left: 9vw;
    opacity: 0;
  }
}
.confetti-66 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(107.3686373295deg);
  animation: drop-66 3.3927671848s 0.4478486005s 3;
}

@keyframes drop-66 {
  25% {
    top: -14vh;
    left: 12vw;
    opacity: 1.4355470577;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-67 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 12px;
  opacity: 0;
  transform: rotate(118.2336241864deg);
  animation: drop-67 3.7887074351s 0.2327925742s 3;
}

@keyframes drop-67 {
  25% {
    top: -1vh;
    left: -7vw;
    opacity: 1.2680437528;
  }
  100% {
    top: 50vh;
    left: -13vw;
    opacity: 0;
  }
}
.confetti-68 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: -10px;
  opacity: 0;
  transform: rotate(245.8541878741deg);
  animation: drop-68 3.5300246538s 0.9502315345s 3;
}

@keyframes drop-68 {
  25% {
    top: -22vh;
    left: -3vw;
    opacity: 1.3167737017;
  }
  100% {
    top: 50vh;
    left: 11vw;
    opacity: 0;
  }
}
.confetti-69 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(310.4197250647deg);
  animation: drop-69 3.5758735902s 0.7465608049s 3;
}

@keyframes drop-69 {
  25% {
    top: -2vh;
    left: -4vw;
    opacity: 0.5516577067;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-70 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: -8px;
  opacity: 0;
  transform: rotate(123.6194349402deg);
  animation: drop-70 3.8343501952s 0.9473897745s 3;
}

@keyframes drop-70 {
  25% {
    top: -24vh;
    left: -8vw;
    opacity: 1.3911053115;
  }
  100% {
    top: 50vh;
    left: -11vw;
    opacity: 0;
  }
}
.confetti-71 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -9px;
  opacity: 0;
  transform: rotate(318.6222025701deg);
  animation: drop-71 3.3657544332s 0.0267145307s 3;
}

@keyframes drop-71 {
  25% {
    top: -25vh;
    left: -4vw;
    opacity: 0.8167364558;
  }
  100% {
    top: 50vh;
    left: 9vw;
    opacity: 0;
  }
}
.confetti-72 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(245.5098570747deg);
  animation: drop-72 3.7258700176s 0.0812625901s 3;
}

@keyframes drop-72 {
  25% {
    top: -9vh;
    left: -10vw;
    opacity: 1.0948686516;
  }
  100% {
    top: 50vh;
    left: -8vw;
    opacity: 0;
  }
}
.confetti-73 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(197.4842181403deg);
  animation: drop-73 3.9307737128s 0.4890680458s 3;
}

@keyframes drop-73 {
  25% {
    top: -6vh;
    left: 15vw;
    opacity: 1.2537098003;
  }
  100% {
    top: 50vh;
    left: 30vw;
    opacity: 0;
  }
}
.confetti-74 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: 2px;
  opacity: 0;
  transform: rotate(230.7799267263deg);
  animation: drop-74 3.000312705s 0.1755923671s 3;
}

@keyframes drop-74 {
  25% {
    top: -15vh;
    left: 5vw;
    opacity: 0.675786627;
  }
  100% {
    top: 50vh;
    left: -4vw;
    opacity: 0;
  }
}
.confetti-75 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 5px;
  opacity: 0;
  transform: rotate(100.8694368066deg);
  animation: drop-75 3.6915091669s 0.6811098274s 3;
}

@keyframes drop-75 {
  25% {
    top: -21vh;
    left: -6vw;
    opacity: 1.0300396825;
  }
  100% {
    top: 50vh;
    left: -14vw;
    opacity: 0;
  }
}

